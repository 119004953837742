import logo from './logo.svg';
import './App.css';
import React from 'react';
import DailyProgressReportForm from './DailyProgressReportForm';


function App() {
  return (
    <div className="App">
      <h1>Daily Progress Report</h1>
      <DailyProgressReportForm />
    </div>
  );
}

export default App;
