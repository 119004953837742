import React, { useState } from 'react';
import axios from 'axios';

const DailyProgressReportForm = () => {
  const [reportData, setReportData] = useState({
    date: '',
    site: '',
    weather: '',
    workCompleted: '',
    materialsUsed: '',
    issues: '',
  });

  const handleChange = (e) => {
    setReportData({
      ...reportData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post('https://bv1xnmc24e.execute-api.us-east-1.amazonaws.com/dpr-test/SubmitReport', reportData);
      alert('Report submitted successfully');
    } catch (error) {
      console.error('Error submitting report', error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <label>
        Date:
        <input type="date" name="date" value={reportData.date} onChange={handleChange} />
      </label><br></br>
      <label>
        Site:
        <input type="text" name="site" value={reportData.site} onChange={handleChange} />
      </label><br></br>
      <label>
        Weather:
        <input type="text" name="weather" value={reportData.weather} onChange={handleChange} />
      </label><br></br>
      <label>
        Work Completed:
        <textarea name="workCompleted" value={reportData.workCompleted} onChange={handleChange} />
      </label><br></br>
      <label>
        Materials Used:
        <textarea name="materialsUsed" value={reportData.materialsUsed} onChange={handleChange} />
      </label><br></br>
      <label>
        Issues:
        <textarea name="issues" value={reportData.issues} onChange={handleChange} />
      </label><br></br>
      <button type="submit">Submit Report</button>
    </form>
  );
};

export default DailyProgressReportForm;
